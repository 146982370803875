<template>
  <form
    @change="handleRadio"
    class="date-container row justify-content-between"
    ref="dateContainer"
  >
    <vs-radio
      v-model="picked"
      :val="$t('helpers.onPage')"
      class="col-md-4 col-sm-12"
    >
      {{ $t('helpers.onPage').toUpperCase() }}
    </vs-radio>
    <vs-radio
      v-model="picked"
      :val="$t('helpers.today')"
      class="col-md-4 col-sm-12"
    >
      {{ $t('helpers.today').toUpperCase() }}
    </vs-radio>
    <vs-radio
      v-model="picked"
      :val="$t('helpers.month')"
      class="col-md-4 col-sm-12"
    >
      {{ $t('helpers.month').toUpperCase() }}
    </vs-radio>
  </form>
</template>

<script>
export default {
  data() {
    return {
      picked: this.$t('helpers.onPage'),
    };
  },
  methods: {
    handleRadio() {
      this.$emit('handleRadio', this.picked);
    },
  },
};
</script>
