<template>
  <Layout>
    <PageHeader
      :title="title"
      moda_title="Datos del operador"
      ref="pageForm"
      :items="items"
    >
    </PageHeader>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-md-3 col-sm-9">
                <label class="d-inline-flex align-items-center w-100">
                  <span class="m-2">{{ $t('filter.from') }}: </span>
                  <vs-input v-model="filterData.fromDate" type="date">
                  </vs-input>
                  <vs-input
                    class="ms-2"
                    type="time"
                    v-model="filterData.fromHour"
                    v-if="filterData.fromDate"
                  />
                </label>
              </div>
              <div class="col-md-3 col-sm-9">
                <label class="d-inline-flex align-items-center w-100">
                  <span class="m-2">{{ $t('filter.until') }}: </span>
                  <vs-input v-model="filterData.toDate" type="date"></vs-input>
                  <vs-input
                    class="ms-2"
                    type="time"
                    v-model="filterData.toHour"
                    v-if="filterData.toDate"
                  />
                </label>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-12 d-flex justify-content-start">
                <label
                  class="d-inline-flex align-items-center"
                  style="width: max-content"
                >
                  {{ $t('filter.gameType') }}:
                </label>
                <select
                  v-model="filterData.gameType"
                  name="client"
                  class="form-select form-select-sm my-2 ms-2 border-0 shadow-lg w-50"
                  placeholder="Seleccione.."
                  @change="getGames"
                >
                  <option disabled selected>
                    {{ $t('helpers.select') }}...
                  </option>
                  <option
                    v-for="(item, index) in gamesTypes"
                    :key="index"
                    :value="item.value"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <div class="col-sm-12 col-md-4" v-if="filterData.gameType">
                <label class="d-inline-flex align-items-center w-100">
                  {{ $t('sidebar.games.text') }}:
                  <select
                    v-model="filterData.rouletteId"
                    name="client"
                    class="form-select form-select-sm m-2 border-0 shadow-lg"
                    placeholder="Seleccione.."
                  >
                    <option disabled selected>
                      {{ $t('helpers.select') }}...
                    </option>
                    <option value="*">ALL</option>
                    <option
                      v-for="(item, index) in roulettes"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </label>
              </div>
              <div class="col-sm-12 col-md-4" v-if="checkAdmins()">
                <label class="d-inline-flex align-items-center w-100">
                  {{ $t('filter.client') }}:
                  <select
                    v-model="filterData.clientId"
                    @change="getOperatorClient(true)"
                    name="client"
                    class="form-select form-select-sm m-2 border-0 shadow-lg"
                    placeholder="Seleccione.."
                  >
                    <option disabled selected>
                      {{ $t('helpers.select') }}...
                    </option>
                    <option
                      v-for="(item, index) in clients"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </label>
              </div>
              <div class="col-sm-12 col-md-4" v-if="userRole !== 'OPERATOR'">
                <label
                  class="d-inline-flex align-items-center w-100"
                  v-if="filterData.clientId"
                >
                  {{ $t('filter.operator') }}:
                  <select
                    v-model="filterData.operator"
                    name="client"
                    class="form-select form-select-sm m-2 border-0 shadow-lg"
                    placeholder="Seleccione.."
                    @change="getRoulettesOperator"
                  >
                    <option disabled selected>
                      {{ $t('helpers.select') }}...
                    </option>
                    <option
                      v-for="(item, index) in operators"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </label>
              </div>
              <div class="col-sm-12 col-md-4" v-if="filterData.operator">
                <label class="d-inline-flex align-items-center w-100">
                  WL:
                  <select
                    v-model="filterData.wl"
                    name="wl"
                    class="form-select form-select-sm m-2 border-0 shadow-lg"
                    placeholder="Seleccione.."
                  >
                    <option disabled selected>
                      {{ $t('helpers.select') }}...
                    </option>
                    <option
                      v-for="(item, index) in mapWls"
                      :key="index"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                </label>
              </div>
              <div class="col-sm-12 col-md-4" v-if="filterData.clientId">
                <label class="d-inline-flex align-items-center w-100">
                  {{ $t('filter.currency') }}:
                  <select
                    v-model="filterData.currency"
                    name="client"
                    class="form-select form-select-sm m-2 border-0 shadow-lg"
                    placeholder="Seleccione.."
                  >
                    <option disabled selected>
                      {{ $t('helpers.select') }}...
                    </option>
                    <option value="*">ALL</option>
                    <option
                      v-for="(item, index) in currencies"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </label>
              </div>
            </div>
            <div class="col-sm-12 col-md-4">
              <label class="d-inline-flex align-items-center w-100 m-1">
                <vs-tooltip>
                  <span>{{ $t('filter.player') }} Admin:</span>
                  <vs-switch v-model="playerAdmin" class="m-1" />
                  <template #tooltip
                    >Habilitar para filtrar con jugadores de test</template
                  >
                </vs-tooltip>
              </label>
              <!-- <label class="d-inline-flex align-items-center w-100 m-1">
                <vs-tooltip>
                  <span>GGR 2:</span>
                  <vs-switch v-model="ggr2" class="m-1" />
                  <template #tooltip>Habilitar para usar GGR 2</template>
                </vs-tooltip>
              </label> -->
            </div>
            <div class="d-flex align-items-center mt-2">
              <vs-select
                placeholder="API VERSION"
                v-model="filterData.apiVersion"
              >
                <vs-option label="V1" value="V1">V1</vs-option>
                <vs-option label="V2" value="V2">V2</vs-option>
              </vs-select>
              <vs-button :disabled="disableFilter">
                <downloadexcel
                  :fetch="exportData"
                  :fields="json_fields"
                  :name="fileName"
                >
                  {{ $t('filter.downloadExcel') }}
                </downloadexcel>
              </vs-button>
              <DateRange @handleRadio="handleRadio" />
              <vs-button
                type="button"
                @click="filtrar"
                :disabled="disableFilter"
                :loading="fetching"
              >
                {{ btnFilterText(fetching) }}
              </vs-button>
              <vs-button
                type="button"
                success
                @click="cancelFilter"
                :disabled="!Object.entries(filterData).length"
              >
                {{ $t('filter.cancel') }}
              </vs-button>
            </div>

            <div class="row mt-2" v-if="tableData.length">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    {{ $t('filter.show') }}&nbsp;
                    <vs-select v-model="perPage" color="dark" class="shadow-lg">
                      <vs-option
                        v-for="(op, index) in pageOptions"
                        :key="index"
                        :label="op"
                        :value="op"
                        >{{ op }}</vs-option
                      >
                    </vs-select>
                    &nbsp;{{ $t('filter.entries') }}
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center m-2">
                    {{ $t('filter.search') }}:
                    <vs-input
                      v-model="filter"
                      type="search"
                      primary
                      class="shadow-lg"
                    ></vs-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
              <!-- <TotalRegister :totalRegister="totalRegister"></TotalRegister> -->
            </div>
            <!-- Table -->
            <b-overlay :show="loading" rounded="lg">
              <div class="table-responsive mb-0" v-if="tableData.length">
                <b-table
                  :items="tableData"
                  :fields="fields"
                  responsive="sm"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :sort-by="sortBy"
                  :sort-desc="sortDesc"
                  foot-clone
                >
                  <template #cell(createdAt)="data">
                    {{
                      moment
                        .utc(data.item.createdAt)
                        .format('DD-MM-YYYY HH:mm:ss')
                    }}
                  </template>

                  <template #cell(bet)="data">
                    <div>
                      <a
                        style="cursor: pointer"
                        title="Ver detalles"
                        variant="outline-primary"
                        @click="openDetails()"
                        >{{ data.item.bet }}</a
                      >
                    </div>
                  </template>
                  <!-- <template #cell(USDBETS)="data">
                    {{
                      data.item.currency &&
                      exchangeTransform(
                        data.item.totalBet,
                        data.item.currency.usdExchange
                      )
                    }}
                  </template>
                  <template #cell(USDWIN)="data">
                    {{
                      data.item.currency &&
                      exchangeTransform(
                        data.item.totalWin,
                        data.item.currency.usdExchange
                      )
                    }}
                  </template>
                  <template #cell(USDPROFIT)="data">
                    {{
                      data.item.currency &&
                      exchangeTransform(
                        data.item.totalProfit,
                        data.item.currency.usdExchange
                      )
                    }}
                  </template> -->

                  <template #foot(name)>
                    <span>TOTAL</span>
                  </template>

                  <template #foot(currency)>
                    <span>-</span>
                  </template>

                  <template #foot(rounds)>
                    <span>{{ formatNumber(total.total_rounds) }}</span>
                  </template>

                  <template #foot(bets)>
                    <span>{{ formatNumber(total.total_betsAmount) }}</span>
                  </template>

                  <template #foot(wins)>
                    <span>{{ formatNumber(total.total_wins) }}</span>
                  </template>

                  <template #foot(profit)>
                    <span>{{ formatNumber(total.total_profit) }}</span>
                  </template>
                </b-table>
                <div v-if="!tableData.length" class="text-center">
                  {{ $t('tableReports.noResults') }}
                </div>
              </div>
            </b-overlay>
            <p
              class="text-center my-2"
              v-if="searchActive && !tableData.length && !fetching"
            >
              {{ $t('tableReports.noResults') }}
            </p>
            <div class="row" v-if="tableData.length">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <button
                      class="btn btn-secondary mx-2 uil-angle-left"
                      @click="beforePage"
                      :disabled="!skip"
                    ></button>
                    <button
                      class="btn btn-primary mx-2 uil-angle-right"
                      @click="nextPage"
                      :disabled="tableData.length < limit"
                    ></button>
                  </ul>
                  <!-- <pagination
                    :totalPages="totalPages"
                    :currentPage="currentPage"
                  ></pagination> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import appConfig from '@/app.config';
import DateRange from '../components/DateRange.vue';
import Layout from '@/views/layouts/main';
import PageHeader from '@/components/page-header-simple';
import downloadexcel from 'vue-json-excel';
import Swal from 'sweetalert2';
import { breadCrumbData } from '../../../../helpers/breadcrumbs/breadCrumbs';
import { onlyAdmins } from '../../../../router/guards/roles';

/**
 * Users-table component
 */
export default {
  page: {
    title: 'GGR Table',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    downloadexcel /* Pagination */ /* TotalRegister */,
    DateRange,
  },
  data() {
    return {
      // PLAYER ADMIN
      playerAdmin: false,
      ggr2: false,
      //
      searchActive: false,
      fetching: false,
      //
      fileName: 'GGR.xls',
      json_fields: {
        [this.$t('betDetail.roulette')]: 'name',
        ['$']: 'currency',
        ['# Bets']: 'rounds',
        [this.$t('tableReports.totalBet')]: 'bets',
        [this.$t('tableReports.totalWin')]: 'wins',
        [this.$t('tableReports.totalProfit')]: 'profit',
      },
      tableData: [],
      totalRegister: 0,
      totalPages: '...',
      title: this.$t('sidebar.reports.subItems.ggr'),
      items: breadCrumbData.ggr,
      loading: false,
      filterData: {
        apiVersion: 'V2',
      },
      dataDetails: {},
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100, 1000, 'ALL'],
      filter: null,
      filterOn: ['currency'],
      clients: [],
      operators: [],
      roulettes: [],
      sortBy: 'name',
      sortDesc: false,
      fields: [
        {
          key: 'name',
          sortable: true,
          label: 'JUEGO',
          class: 'space-nowrap',
        },
        {
          key: 'operator',
          sortable: true,
          label: 'OPERADOR',
          class: 'space-nowrap',
        },
        {
          key: 'currency',
          sortable: true,
          label: this.$t('tableReports.currency'),
          class: 'space-nowrap',
        },
        {
          key: 'rounds',
          sortable: true,
          label: '# Bets'.toUpperCase(),
          class: 'space-nowrap text-end',
          formatter: (value) => {
            return this.formatNumber(value);
          },
        },
        {
          key: 'bets',
          sortable: true,
          label: this.$t('tableReports.totalBet').toUpperCase(),
          class: 'space-nowrap text-end',
          formatter: (value) => {
            return this.formatNumber(value);
          },
        },
        {
          key: 'wins',
          sortable: true,
          label: this.$t('tableReports.totalWin').toUpperCase(),
          class: 'space-nowrap text-end',
          formatter: (value) => {
            return this.formatNumber(value);
          },
        },
        {
          key: 'profit',
          sortable: true,
          label: this.$t('tableReports.profit').toUpperCase(),
          class: 'space-nowrap text-end',
          formatter: (value) => {
            return this.formatNumber(value);
          },
        },
      ],

      clientFilter: null,
      skip: 0,
      currencies: [],
      limit: null,
      disabledNextPage: null,
      disabledPreviousPage: null,
      userData: '',
      userRole: '',
      gameType: null,
      total: null,

      // ROLES
      readAllRoles: onlyAdmins,
    };
  },
  computed: {
    disableFilter() {
      const requiredKeys = ['fromDate', 'toDate', 'gameType'];

      if (
        Object.keys(this.filterData).filter((a) => requiredKeys.includes(a))
          .length === requiredKeys.length
      )
        return false;
      return true;
    },
    ...mapGetters({
      mapCurrencies: 'currencies/getCurrencies',
      getOperatorsClient: 'operators/getOperators',
      getMapClients: 'clients/getClients',
      mapWls: 'reports/getWls',
      mapGgr: 'reports/getGgr',
      formatNumber: 'reports/formatNumbers',
      mapClientRoulettes: 'clients/getClientRoulettes',
      mapOperators: 'operators/getOperators',
      mapGames: 'games/getGames',
      gamesTypes: 'reports/getGamesTypes',
      btnFilterText: 'reports/getBtnFilterText',
      userIsClient: 'user/isClient',
    }),
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;

    this.getUserRole();
    this.getCurrencies();
  },
  methods: {
    getUserRole() {
      this.userData = this.$store.getters['user/getUserData'];
      this.userRole = this.userData.role.name;

      if (this.userIsClient) {
        this.filterData.clientId = this.userData.client;
        this.getOperatorClient();
        this.getRoulettesClient();
      } else if (this.userRole === 'OPERATOR') {
        this.filterData.operator = this.userData.operator;
        this.filterData.operatorId = this.userData.operator;
      }
      this.getClients();
    },
    async getOperatorClient(filter) {
      const clientId = filter ? this.filterData.clientId : this.userData.client;
      try {
        const options = { params: { client: clientId } };
        await this.fetchOperators({ options, optionsAdminApi: { clientId } });
        this.operators = this.mapOperators;

        this.getRoulettesClient();
      } catch (err) {
        console.log('Error al obtener operadores del cliente', err);
      }
    },
    async getClients() {
      try {
        await this.fetchClients();
        this.clients = this.getMapClients;
      } catch (error) {
        console.log('ERROR GET CLIENTS', error);
      }
    },
    async getCurrencies() {
      try {
        await this.fetchCurrencies({
          role: this.userData.role.name,
          user: this.userData,
        });
        this.currencies = this.mapCurrencies;
      } catch (err) {
        console.log(err);
      }
    },
    async getGames() {
      try {
        if (this.userIsClient) {
          this.getRoulettesClient();
        } else if (this.userRole === 'OPERATOR') {
          this.getRoulettesOperator();
        } else {
          await this.fetchGames({ gameType: this.filterData.gameType });
          this.roulettes = this.mapGames;
        }
      } catch (error) {
        console.log('ERROR GET GAMES', error);
      }
    },
    async filtrar() {
      this.fetching = true;
      this.searchActive = true;
      this.loading = true;

      try {
        await this.fetchGgrByGame({
          filterData: {
            ...this.filterData,
            operatorId: this.filterData.operator,
            limit: this.perPage,
            page: this.currentPage,
          },
        });

        const { data, total } = this.mapGgr;

        this.tableData = data;
        this.total = total;
        this.loading = false;
        this.fetching = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.fetching = false;
      }
    },
    async getRoulettesClient() {
      const client = this.filterData.clientId || this.userData.client;
      try {
        const games = await this.fetchGamesClient({
          ...this.filterData,
          client,
        });

        this.roulettes = games;
      } catch (err) {
        console.log('Err', err);
      }
    },
    async getRoulettesOperator() {
      const operatorId = this.filterData.operator || this.userData.operator;
      try {
        const games = await this.fetchGamesOperator({
          ...this.filterData,
          operatorId,
        });
        this.roulettes = games;
      } catch (err) {
        console.log('Error al obtener ruletas del operador', err);
      }
    },
    cancelFilter() {
      this.searchActive = false;

      this.filterData = {
        apiVersion: 'V2',
      };
      this.getUserRole();
    },
    openDetails() {
      let rouletteId =
        this.filterData.rouletteId == undefined
          ? ''
          : `rouletteId=${this.filterData.rouletteId}`;
      let operatorId =
        this.filterData.operator == undefined
          ? ''
          : `&operatorId=${this.filterData.operator}`;
      let fromDate =
        this.filterData.fromDate == undefined
          ? ''
          : `&fromDate=${this.filterData.fromDate}`;
      let toDate =
        this.filterData.toDate == undefined
          ? ''
          : `&toDate=${this.filterData.toDate}`;
      let params = rouletteId + operatorId + fromDate + toDate;
      window.location.href = `/reports/ggr/detail?${params}`;
    },
    async handleRadio(value) {
      this.picked = value;
      switch (this.picked) {
        case this.$t('helpers.onPage'):
          break;
        case this.$t('helpers.today'): {
          const initialDate = new Date().toJSON().split('T')[0];
          this.filterData = {
            ...this.filterData,
            fromDate: initialDate,
            toDate: initialDate,
          };
          break;
        }
        case this.$t('helpers.month'): {
          let from = 1;
          let to = this.daysInMonth(
            new Date().getMonth(),
            new Date().getFullYear()
          );
          const initialDate = new Date().toJSON().split('T')[0].split('-');
          let fromDate = [initialDate[0], initialDate[1], from].join('-');
          let toDate = [initialDate[0], initialDate[1], to].join('-');
          this.filterData = { ...this.filterData, fromDate, toDate };
          break;
        }
        default:
          break;
      }
      return true;
    },
    async exportData() {
      if (!this.filterData.fromDate || !this.filterData.toDate)
        return Swal.fire(
          'Es necesario seleccionar un rango de fecha',
          '',
          'info'
        );

      this.fetching = true;

      await this.fetchGgrByGame({
        filterData: {
          ...this.filterData,
          operatorId: this.filterData.operator,
          limit: this.perPage,
          page: this.currentPage,
        },
      });

      const { data } = this.mapGgr;

      if (data.length) {
        this.fetching = false;

        const formatted = data.map((i) => {
          return {
            ...i,
            bets: this.formatNumber(i.bets),
            wins: this.formatNumber(i.wins),
            profit: this.formatNumber(i.profit),
          };
        });

        return formatted;
      } else {
        Swal.fire({
          position: 'top-end',
          icon: 'danger',
          title: 'Advertencia, no se encuentran datos asociados',
          showConfirmButton: false,
          timer: 2000,
        });
      }

      this.fetching = false;
    },
    // PAGINATION
    handleChange() {
      this.limit = this.perPage;
      this.currentPage = 1;
      this.filtrar();
    },
    nextPage() {
      this.skip += this.perPage;
      this.currentPage++;
      this.filtrar();
    },
    beforePage() {
      this.skip -= this.perPage;
      this.currentPage--;
      this.filtrar();
    },
    checkAdmins() {
      return this.readAllRoles.includes(this.userRole);
    },
    daysInMonth(month, year) {
      return new Date(year, month + 1, 0).getDate();
    },
    exchangeTransform(amount, usdExchange) {
      if (amount && usdExchange) {
        if (usdExchange > 1) {
          return (amount / usdExchange).toFixed(5);
        }
        return (usdExchange * amount).toFixed(5);
      }
      return 0;
    },
    // VUEX
    ...mapActions({
      fetchGames: 'games/fetchGames',
      fetchCurrencies: 'currencies/fetchCurrencies',
      fetchOperatorsClient: 'operators/fetchOperatorsByClientId',
      fetchOperators: 'operators/fetchOperators',
      fetchClients: 'clients/fetchClients',
      fetchWls: 'reports/fetchWls',
      fetchGgrByGame: 'reports/fetchGgrByGame',
      fetchGamesClient: 'clients/fetchGames',
      fetchGamesOperator: 'operators/fetchGames',
    }),
  },
  middleware: 'authentication',
  watch: {
    playerAdmin(data) {
      this.filterData.isAdmin = data ? data : '';
    },
    ggr2(data) {
      this.filterData.ggr2 = data ? data : '';
    },
    'filterData.operator': async function (operator) {
      await this.fetchWls({ operator });
    },
    'filterData.fromHour': function (hour) {
      if (!hour) return (this.filterData.fromDateHour = '');
      this.filterData.fromDateHour = `${this.filterData.fromDate} ${hour}`;
    },
    'filterData.toHour': function (hour) {
      if (!hour) return (this.filterData.toDateHour = '');
      this.filterData.toDateHour = `${this.filterData.toDate} ${hour}`;
    },
    mapGgr(ggr) {
      const { data, total } = ggr;

      this.tableData = data;
      this.total = total;
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.space-nowrap {
  white-space: nowrap !important;
}
</style>
